<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card outlined class="mt-0">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          Security Vulnerabilities Published In
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
          </v-col>
          <v-col cols="12">
            <v-data-table
              dense
              fixed-header
              locale="en"
              hide-default-footer
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :search="search"
              :headers="headers"
              :items="vulnerabilities"
              class="elevation-1"
              @pagination="totalResults = $event.itemsLength"
            >
              <template v-slot:item.cve_ID="{ item }">
                <router-link :to="'/cve/' + item.cve_ID">
                  {{ item.cve_ID }}
                </router-link>
              </template>
            </v-data-table>
          </v-col>
          <v-col class="mt-2 flex-wrap" cols="12">
            <div style="width:200px" class="float-sm-left d-flex mb-2">
              <v-select
                v-model="itemsPerPage"
                style="width:85px"
                class="px-2 py-0 my-0 "
                :items="rowsPerPageItems"
                flat
                hide-details
              />
              <span class="mt-2  text-body-1">
                {{ (page - 1) * itemsPerPage + 1 }}-{{
                  totalResults > page * itemsPerPage ? page * itemsPerPage : totalResults
                }}
                {{ "of" }} {{ totalResults }}
              </span>
            </div>
            <v-pagination
              v-model="page"
              class="d-flex"
              :length="Math.ceil(totalResults / itemsPerPage)"
              total-visible="7"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      vulnerabilities: [],
      loading: false,
      year: 2021,
      month: 1,
      search: "",
      page: 1,
      itemsPerPage: 20,
      totalResults: 10,
      rowsPerPageItems: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 50, value: 50 }
      ],
      headers: [
        { text: "#", value: "no" },
        { text: "CVE ID", value: "cve_ID", width: "140px" },
        { text: "CWE ID", value: "vendor_name" },
        { text: "# of Exploits", value: "vce_entries" },
        { text: "Vulnerability Type(s)", value: "product_type" },
        { text: "Publish Date", value: "publishedDate", width: "110px" },
        { text: "Update Date", value: "lastModifiedDate", width: "110px" },
        { text: "Score", value: "score" },
        { text: "Gained Access Level", value: "inventory" },
        { text: "Access", value: "access" },
        { text: "Complexity", value: "inventory" },
        { text: "Authentication", value: "authentication" },
        { text: "Conf.", value: "confidentiality" },
        { text: "Integ.", value: "inventory" },
        { text: "Avail.", value: "inventory" }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      if (to.name == "MonthVulnerabilities" || to.name == "YearVulnerabilities") {
        this.year = this.$route.params.pathMatch
        this.month = this.$route.params["1"]
        this.getRecords()
      }
    }
  },
  created() {
    this.year = this.$route.params.pathMatch
    this.month = this.$route.params["1"]
    this.getRecords()
  },
  methods: {
    getRecords() {
      this.loading = true
      this.vulnerabilities = []
      let url =
        "cve_vulnerability_by_month/" + this.year + "-" + (this.month < 10 ? "0" + this.month : this.month) + "/10000/0"
      if (!this.month) {
        url = "cve_vulnerability_by_month/" + this.year + "/100000/0"
      }
      axios
        .get(url)
        .then(response => {
          //this.$log("vul month ", response)
          response.data.map(itm => {
            itm.publishedDate = itm.publishedDate.split("T")[0]
            itm.lastModifiedDate = itm.lastModifiedDate.split("T")[0]
            this.vulnerabilities.push(itm)
          })
          //this.vulnerabilities = response.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
